/* .error-message {
  color: $err-red;
} */

@for $i from 1 through 5 {
  .pd-#{$i} {
    padding: #{$i}rem;
  }

  .pd-t-#{$i} {
    padding-top: #{$i}rem;
  }

  .pd-b-#{$i} {
    padding-bottom: #{$i}rem;
  }

  .pd-r-#{$i} {
    padding-right: #{$i}rem;
  }

  .pd-l-#{$i} {
    padding-left: #{$i}rem;
  }

  .mg-#{$i} {
    margin: #{$i}rem;
  }

  .mg-t-#{$i} {
    margin-top: #{$i}rem;
  }

  .mg-b-#{$i} {
    margin-bottom: #{$i}rem;
  }

  .mg-r-#{$i} {
    margin-right: #{$i}rem;
  }

  .mg-l-#{$i} {
    margin-left: #{$i}rem;
  }
}

.align-center-of-display {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .download-button {
    width: 16.8rem;
    height: 4.9rem;
    margin-right: 2.8rem;
    cursor: pointer;
  }
}

.change-image-btn {
  position: absolute;
  width: 140px;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  @include font($white, "OpenSans-SemiBold", 1.2rem, normal);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
  background-color: $primary;
  cursor: pointer;
  z-index: 5;
  border: 0px; // button default style remove
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}
