.faq-section {
	.faq-box {
		padding: 10rem 10rem 5rem 10rem;
		p {
			@include font($grey-33, "OpenSans-Regular", 1.6rem, normal);
		}
		h2 {
			@include font($grey, "LibreBaskerville-Bold", 2.8rem, normal);
			margin: 1.5rem 0rem;
		}
		.panel-title {
			a {
				@include font($grey-33, "OpenSans-Regular", 1.6rem, normal);
				text-decoration: none;
			}
		}
		.panel-body {
			@include font($grey-33, "OpenSans-Regular", 1.4rem, normal);
		}
		.glyphicon {
			float: right;
		}
		.icon {
			float: none;
			margin: 0 0.5rem;
		}
		.imp-note {
			font-weight: bold;
			.note {
				color: $err-red;
				font-size: 1.8rem;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.faq-section {
		.faq-box {
			padding: 10rem 3rem 3rem 3rem;
		}
	}
}
