.modal-dialog {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  margin: 0;
  .modal-content {
    width: 59rem;
    border-radius: 0.4rem;
    border: none !important;
    .modal-body {
      .close-btn {
        float: right;
        padding: 0.2rem;
        cursor: pointer;
      }
      .modal-container {
        width: 100%;
        .middle {
          h3 {
            @include font($grey, "LibreBaskerville-Bold", 1.6rem, normal);
            letter-spacing: 0.9px;
          }
          .content {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            margin: 0;
            padding: 0;
            @include font($grey, "OpenSans-Regular", 1.2rem, normal);
            line-height: 1.8rem;
            letter-spacing: 1px;
            max-height: 20rem;
            overflow: auto;

            p {
              display: inline-block;
              vertical-align: middle;
              margin: 0px;
              width: 50rem;
            }

            img {
              display: inline-block;
              vertical-align: middle;
              margin-left: 0.7rem;
            }
          }

          .hashtag-content {
            overflow: auto;
            // max-height: 12vh;
            width: 57vw;
          }

          .caption-content {
            overflow: auto;
            height: 45vh;
            width: 57vw;
          }
        }

        .bottom {
          .modal-copy-btn {
            display: inline-block;
            border-radius: 0.4rem;
            padding: 0.5rem 2.5rem;
            background-color: $primary;
            @include font($white, "OpenSans-SemiBold", 1.2rem, normal);
            -webkit-align-content: center;
            align-content: center;
            text-align: center;
            cursor: pointer;
            width: 10rem;
          }

          .copied-btn {
            background-color: $green-00;
          }

          .light-text {
            @include font($grey, "OpenSans-Regular", 1.8rem, normal);
            letter-spacing: 1.3px;
          }
        }
      }
      .profile-image-container {
        height: 14.7rem;
        width: 14.7rem;
        border-radius: 100%;
        overflow: hidden;
        border: 3px solid $grey-ec;
        position: relative;

        img {
          background-color: $primary;
          position: absolute;
          top: 50%;
          left: 50%;
          height: 100%;
          width: auto;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }

        img.portrait {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.copy-modal {
  .modal-dialog {
    margin: 0;

    .modal-bg-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      h3 {
        @include font($grey-33, "LibreBaskerville-Bold", 1.8rem, normal);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }
}

.error-modal {
  .modal-dialog {
    /* height: 44.6rem;
    width: 44.9rem;
    margin-top: 20vh; */
    .modal-content {
      box-shadow: none;
      border-radius: 1.5rem;
      .error-modal-content {
        padding: 2rem;
        img {
          display: block;
          margin: 0 auto;
          /* width: 22rem;
          height: 17rem; */
        }
        h3 {
          @include font($grey-33, "LibreBaskerville-Bold", 3rem, normal);
          text-align: center;
          letter-spacing: 0.2px;
        }
        h4 {
          @include font($grey-9b, "OpenSans-Regular", 1.6rem, normal);
          line-height: 2.3rem;
          letter-spacing: 0.1px;
          text-align: center;
        }
      }
    }
  }
}

.image-modal {
  .modal-content {
    border: none !important;
    .modal-bg-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .result-image {
        //height: 40rem;
        width: 100%;
        position: relative;
        max-height: 50rem;
        object-fit: contain;
      }
      .close-modal {
        width: 2rem;
        position: absolute;
        right: 0;
        margin: 0.5rem;
      }
    }
  }
}

.disable {
  opacity: 0.6;
  cursor: not-allowed !important;
}
