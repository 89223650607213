//Mobile Navbar
.mobile-nav.navbar-default {
  background: $white;
  //padding: 2rem 1.5rem 2rem 1.5rem;
  padding: 0rem 1.5rem;
  height: 10%;
  margin: 0;
  .container {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    .search-icon {
      //margin-top: 0.7rem;
      position: absolute;
      right: 2rem;
    }
  }
}

.navbar {
  border: none !important;
}

.output-nav.navbar-default {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
  background: $primary;
  //border-color: none;
  padding: 1rem;
  margin: 0;
  h3 {
    //animation: 1s ease-out 0s 1 slideInFromBottom;
    @include font($white, "LibreBaskerville-Bold", 1.3rem, normal);
    margin: 1.7rem 3rem;
  }
  .back-button {
    position: absolute;
    padding: 1.5rem 0rem;
    z-index: 1;
  }
}

.output-navbar {
  width: 100%;
  height: 5rem;
  position: absolute;
  background-image: linear-gradient($grey-33, $gradient-shadow);
  opacity: 0.6;
}

.back-button {
  position: fixed;
  padding: 2rem;
}

//Main Wrapper
.mobile-wrapper {
  overflow: hidden;
  //Middle Section
  .ripple-section {
    /* position: relative;
    height: 53vh; */
    position: absolute;
    height: 55%;
    width: 100%;
    top: 10%;
    //margin-top: 10vh;
    // bottom: 1.2rem;
    // left: 1.2rem;
    overflow: hidden;
    //border-radius: 3rem;
    background: $primary;

    transition: bottom 1s, transform 1s, height 0.3s 1s, width 0.3s 1s, left 0.3s 1s;
    .center-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 10rem;
      height: 10rem;
      z-index: 5;
      overflow: hidden;
      border: 7px solid $primary-light;
      border-radius: 50%;
      img {
        background-color: $primary;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      img.portrait {
        width: 100%;
        height: auto;
      }
    }
    .dashed-border {
      border: 0.3rem dashed $white;
      height: 10rem;
      width: 10rem;
    }

    .bottom-text-content {
      position: absolute;
      width: 25.5rem;
      left: 50%;
      bottom: 16%;
      transform: translate(-50%, 0%);
      @include font($white, "OpenSans-Regular", 1.4rem, normal);
      line-height: 27px;
      letter-spacing: 1.2px;
      text-align: center;
      z-index: 1;
      &.load {
        bottom: 8%;
      }
      .bold-text {
        display: inline-block;
        width: 250px;
        @include font($white, "LibreBaskerville-Bold", 2rem, normal);
        letter-spacing: 0.4rem;
        text-align: center;
      }
      .normal-text {
        width: 250px;
        @include font($white, "OpenSans-Regular", 1.4rem, normal);
        letter-spacing: 0.3rem;
        line-height: 2.1rem;
        width: 188px;
        text-align: center;
      }
    }
    .ripple {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 150px;
      height: 150px;
      border-radius: 50%;
      animation: ripple 4s linear infinite;
    }

    .r2 {
      animation-delay: 0.8s;
    }
    .r3 {
      animation-delay: 1.6s;
    }
    .r4 {
      animation-delay: 2.4s;
    }
    .r5 {
      animation-delay: 3.2s;
    }
    .r6 {
      animation-delay: 4s;
    }
  }

  //Display Content Section
  .mobile-content-bottom {
    //height: 31vh;
    position: absolute;
    width: 100%;
    height: 35%;
    top: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.4rem;
    margin: 0 auto;
    text-align: left;
    .mobile-content-section {
      // padding: 2.5rem 0rem 2.5rem 1.4rem;
      width: 100%;
      padding-bottom: 2rem;
      .normal-text {
        @include font($sub-title, "OpenSans-Regular", 1.6rem, normal);
      }
      .highlight-text {
        @include font($grey-33, "LibreBaskerville-Bold", 2.2rem, bold);
      }
    }
  }

  //Output Section
  .output {
    background-color: $white-f4;
    /* animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1; */
    // animation-fill-mode: forwards;
    //animation: 1s ease-out 0s 1 slideInFromBottom;
    //height: 100vh;
    .clipboard-image {
      animation: fadeInAnimation ease 1s;
      animation-iteration-count: 1;
      width: 100%;
      object-fit: cover;
      height: 20rem;
      display: flex;
      margin: 0 auto;
    }
    .output-content {
      animation: fadeInAnimation ease 1s;
      animation-iteration-count: 1;
      //animation: 1s ease-out 0s 1 slideInFromBottom;
      padding: 1.6rem;
      .heading {
        @include font($grey, "LibreBaskerville-Bold", 1.3rem, normal);
        padding-bottom: 0.5rem;
        line-height: 19.5px;
        letter-spacing: 0.46px;
        margin-top: 0;
        &.no-heading {
          //display: none;
          visibility: hidden;
        }
      }
      .inner-section {
        background-color: $white;
        padding: 2rem;
        box-shadow: 0 0.4rem 1.4rem 0 $card-shadow;
        .captions {
          @include font($grey-33, "OpenSans-SemiBold", 1.3rem, normal);
          line-height: 19.5px;
          margin: 0;
        }
        .hashtags {
          padding-top: 2rem;
          @include font($grey-33, "OpenSans-Regular", 1.3rem, normal);
          line-height: 19.5px;
        }
        .divide {
          background-color: $primary;
          height: 1rem;
        }
        .copy-img {
          display: flex;
          justify-content: space-between;
        }
      }
      .view-more {
        width: 100%;
        margin-top: 1rem;
        background-color: $primary;
        @include font($white, "OpenSans-Regular", 1.4rem, normal);
        line-height: 2.71;
        border-radius: 0.6rem;
        box-shadow: 0 8px 23px -10px $shadow;
        border: 0;
      }
    }
  }

  //Footer
  .downloading-section {
    /* display: flex;
    justify-content: space-evenly;
    align-items: center; */
    /* position: fixed;
    padding: 0rem 0rem 2rem 1.4rem;
    bottom: 0; */
    padding-top: 1rem;
    width: 100%;
    .playstore {
      margin-right: 1rem;
    }
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
