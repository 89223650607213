$primary: #2f19b4;
$primary-light: #8274d2;
$white-f4: #f4f4f4;
$white: #ffffff;
$sub-title: rgba(51, 51, 51, 0.54);
$grey-33: #333333;
$baby-pink: #ffebf1;
$white-f1: #f1f0fb;
$white-e3: #e3e3e3;
$grey: #494949;
$grey-ec: #ececec;
$grey-9b: #9b9b9b;
$search-bar: rgba(51, 51, 51, 0.54);
$green-00: #009688;
$grey-e1: #e1e1e1;
$shadow: #f0f0f0;
$card-shadow: rgba(0, 0, 0, 0.06);
$gradient-shadow: rgba(6, 6, 6, 0);
$shadow-input: rgba(0, 0, 0, 0.15);

//$yellow: #ffc607;
$err-red: #ed4952;
//$err-green: #55b357;
//$grey-53: #535353;
//$grey-ae: #aeaeae;
//$grey-99: #999999;
//$black: #000000;
