@include font-face(OpenSans-Regular, "../fonts/Open_Sans/OpenSans-Regular", normal, normal, ttf);
@include font-face(OpenSans-SemiBold, "../fonts/Open_Sans/OpenSans-SemiBold", normal, normal, ttf);
@include font-face(LibreBaskerville-Bold, "../fonts/Libre_Baskerville/LibreBaskerville-Bold", bold, normal, ttf);

// Making 1rem=10px
html {
  font-size: 10px;
  height: 100%;
  //overflow-y: hidden;
}

body {
  background-color: $white;
  //height: 100vh;
  height: 100%;
  margin: 0px;
  //overflow-y: hidden;
}
