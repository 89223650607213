.wrapper {
  height: 100vh;
  width: 100%;
  margin: 0px;
  overflow: hidden;
  .left-section {
    position: relative;
    height: inherit;
    width: 50%;
    float: left;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: saturation;
    z-index: 101;

    .brand-logo {
      position: absolute;
      height: 6rem;
      top: 3rem;
      left: 2rem;
    }

    .left-inner-box {
      .mobile-mockup {
        position: absolute;
        height: 70rem;
        //height: 60rem;
        width: 34rem;
        //top: 10%;
        bottom: 30%;
        //bottom: 0%;
        left: 100%;
        transform: translate(-50%, 50%);

        img.mobile-mockup-frame {
          position: absolute;
          height: inherit;
          width: inherit;
          bottom: 0;
          left: 0;
          z-index: 4;
        }

        .mobile-mockup-image-section {
          position: absolute;
          height: 67rem;
          //height: 57rem;
          width: 31rem;
          bottom: 1.2rem;
          left: 1.2rem;
          overflow: hidden;
          border-radius: 3rem;
          background: $primary;

          .center-image {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 15rem;
            height: 15rem;
            z-index: 5;
            overflow: hidden;
            border: 7px solid $primary-light;

            img {
              background-color: $primary;
              position: absolute;
              top: 50%;
              left: 50%;
              height: 100%;
              width: auto;
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }

            img.portrait {
              width: 100%;
              height: auto;
            }
          }

          .dashed-border {
            border: 0.3rem dashed $white;
          }

          .solid-border {
            border: 0.3rem solid $primary-light;
          }

          .ripple {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 150px;
            height: 150px;
            border-radius: 50%;
            animation: ripple 4s linear infinite;
          }

          .r2 {
            animation-delay: 0.8s;
          }
          .r3 {
            animation-delay: 1.6s;
          }
          .r4 {
            animation-delay: 2.4s;
          }
          .r5 {
            animation-delay: 3.2s;
          }
          .r6 {
            animation-delay: 4s;
          }

          .top-text-content {
            position: absolute;
            width: 250px;
            left: 50%;
            top: 10%;
            transform: translate(-50%, 0%);
            line-height: 1.5;
            letter-spacing: 6.4px;
            text-align: center;
            //color: $yellow;
            z-index: 1;
            line-height: 1.36;
            .heading {
              @include font($grey, "LibreBaskerville-Bold", 2.8rem, normal);
              /* font-family: Poppins-Regular;
              font-size: 28px;
              letter-spacing: 0.7px; */
            }
            .content {
              @include font($sub-title, "OpenSans-Regular", 1.8rem, normal);
              /* font-family: Poppins-Thin;
              font-size: 18px;
              letter-spacing: 0.3px; */
            }
          }

          .bottom-text-content {
            position: absolute;
            width: 255px;
            left: 50%;
            top: 60%;
            transform: translate(-50%, 0%);
            @include font($white, "OpenSans-Regular", 1.8rem, normal);
            line-height: 1.8;
            letter-spacing: 0.2rem;
            text-align: center;
            z-index: 1;
            .bold-text {
              display: inline-block;
              width: 250px;
              @include font($white, "LibreBaskerville-Bold", 2rem, normal);
              letter-spacing: 0.5rem;
              text-align: center;
            }
            .normal-text {
              width: 250px;
              @include font($white, "OpenSans-Regular", 1.4rem, normal);
              letter-spacing: 0.3rem;
              line-height: 2.1rem;
              width: 188px;
              text-align: center;
            }
          }
          img {
            position: absolute;
            bottom: -50%;
            left: 50%;
            height: 100%;
            width: auto;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
          img.portrait {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .right-section {
    overflow: hidden;
    height: inherit;
    background-color: $baby-pink;
    .right-inner-box {
      z-index: 99;
      margin: 45% 0 0 33%;
      .normal-text {
        @include font($sub-title, "OpenSans-Regular", 2.4rem, normal);
        margin: 0;
      }
      .heading-text {
        @include font($grey-33, "LibreBaskerville-Bold", 3rem, normal);
        margin: 1rem 0;
      }
      .downloading-section {
        display: flex;
        align-items: center;
        margin-top: 6.7rem;
        .available-label {
          background-color: $grey-33;
          width: 9.7rem;
          height: 2.7rem;
          @include font($white, "OpenSans-Regular", 1.2rem, normal);
          padding: 0.5rem 1rem;
          align-content: center;
          text-align: center;
          margin-right: 2rem;
        }
        .download-button {
          width: 16.8rem;
          height: 4.9rem;
          margin-right: 2.8rem;
        }
        .download-icon {
          margin-right: 20px;
        }
      }
    }
  }
}

/* .error-message {
  @include font($white, "OpenSans-Regular", 1.2rem, normal);
  position: fixed;
  top: 2rem;
  text-align: center;
  z-index: 100000;
  width: 100%;
} */

.file-upload-area {
  width: inherit;
  height: inherit;
  background: $grey-9b;
  z-index: 100;
  position: absolute;
  border-radius: inherit;
  cursor: pointer;
  // opacity: 1;
  opacity: 0;
}

// .before{
//     .left-section{
//         transition: width 1s;

//         .left-inner-box{
//             .mobile-mockup{
//                 // transition: all 1s;
//                 transition: height 1s, width 1s, left 1s, bottom 1s 2s, transform 1s 2s;
//                 // transition: height 1s, width 1s, left 1s, bottom 1.5s, transform 1s;

//                 .mobile-mockup-image-section {
//                     // transition: all 1s;
//                     transition: height 1s, width 1s, left 1s, bottom 1s 2s, transform 1s 2s;
//                     // transition: height 1s, width 1s, left 1s, bottom 1.5s, transform 1s;

//                 }
//             }
//         }
//         .right-inner-box{
//             transition: all 2s 3s;
//         }
//     }

// }

.after {
  .left-section {
    transition: width 0.3s 1s;
    width: 25%;
    .left-inner-box {
      .mobile-mockup {
        transition: bottom 1s, transform 1s, height 0.3s 1s, width 0.3s 1s, left 0.3s 1s;
        /* height: 60rem;
        width: 28rem; */
        height: 50rem;
        width: 24rem;
        bottom: 45%;
        //bottom: 50%;
        left: 75%;
        transform: translate(-50%, 50%);
        .mobile-mockup-image-section {
          transition: bottom 1s, transform 1s, height 0.3s 1s, width 0.3s 1s, left 0.3s 1s;
          /* height: 58.5rem;
          width: 26.5rem; */
          height: 47rem;
          width: 22.5rem;
          //bottom: 0.5rem;
          left: 0.5rem;
        }
      }
    }
  }

  .right-section {
    background-color: $white;
    .right-inner-box {
      // height: 100%;
      // margin: 10% 10%;
      //margin: 9rem 15rem;
      margin: 0rem 15rem;
      top: 50%;
      transform: translateY(-50%);
      // position: absolute;
      position: relative;
      .headding {
        @include font($grey, "LibreBaskerville-Bold", 1.6rem, normal);
        letter-spacing: 1.1px;
        margin-top: 2rem;
      }
      .content {
        overflow: auto;
        // width: 75rem;
        margin: 1rem;
        //@include font($grey, "OpenSans-Regular", 1.4rem, normal);
        line-height: 2rem;
        letter-spacing: 0.5px;
        margin-left: 0;
        .inner-section {
          cursor: pointer;
          position: relative;
          @include font($grey, "OpenSans-Regular", 1.4rem, normal);
          &:hover {
            background: $white-f1;
            color: $primary;
            .right {
              display: inline-block;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
            }
          }

          .left {
            display: inline-block;
            vertical-align: middle;
            width: 96%;
            line-height: 20px;
          }
          .right {
            display: none;
            // display: inline-block;
            vertical-align: middle;
            text-align: center;
            // z-index:1000;
          }
        }
      }

      //Scrollbar for content
      .content::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      .content::-webkit-scrollbar:vertical {
        width: 1rem;
      }

      .content::-webkit-scrollbar:horizontal {
        height: 1rem;
      }

      .content::-webkit-scrollbar-thumb {
        border-radius: 0.8rem;
        border: 0.2rem solid $white;
        background-color: $grey-9b;
      }

      .hashtag-content {
        //max-height: 12rem;
        max-height: 15vh;
      }

      .caption-content {
        //max-height: 36rem;
        max-height: 30vh;
      }

      .downloading-section {
        .download-button {
          width: 14.5rem;
          height: 4.5rem;
        }
      }

      // shimmer css
      .shimmer {
        .animated {
          background-color: $white-e3;
          background: linear-gradient(to right, $white-e3 5%, #eee 20%, $white-e3 35%);
          animation: placeholderShimmer 2s linear 0s infinite normal forwards;
        }
        .title {
          position: relative;
          height: 2.3rem;
          &:after {
            position: absolute;
            right: 0;
            content: "";
            background: $white;
            height: 23px;
            width: 80%;
          }
        }

        .padding {
          height: 1.8rem;
          background: $white;
        }

        .description {
          position: relative;
          height: 1.4rem;
          &:after {
            position: absolute;
            right: 0;
            content: "";
            background: $white;
            height: 1.4rem;
            width: 0%;
          }
        }

        .description-half {
          position: relative;
          height: 1.4rem;
          &:after {
            position: absolute;
            right: 0;
            content: "";
            background: $white;
            height: 1.4rem;
            width: 50%;
          }
        }
      }
    }
  }
}

// .fade{
//     transition: 6s linear all;
// }

// common css
.overlay {
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    //background-color: rgba($color: $primary, $alpha: 0.44);
  }
}

.border {
  border-radius: 6px;
  border: solid 1px $grey-e1;
  // width: 75rem;
  padding: 0.9rem 1.9rem;
}

.center-cropped {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
}

.align-center {
  text-align: center;
}

.mg-auto {
  margin: auto;
}

.trigger {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  background: #aab2bd;
}

.box {
  display: inline-block;
  background-color: tomato;
  width: 200px;
  height: 200px;
}

.clicked .box {
  transform: translate(150px, 150px);
  background-color: green;
  width: 300px;
  height: 300px;
  // transition: transform 1s 2s, background-color 1s, all 2s 3s;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -750px 0;
  }
  100% {
    background-position: 750px 0;
  }
}

@keyframes ripple {
  from {
    background: #5959db;
  }
  to {
    background: $primary;
    width: 800px;
    height: 800px;
    opacity: 0;
  }
}
