.custom-nav.navbar-default {
  background-image: none;
  z-index: 999;
  background: $white;
  box-shadow: 0 2px 30px 0 $shadow-input;
  padding: 1.5rem;
  margin: 0;
  form {
    float: right;
    margin-top: 0.5rem;
    position: relative;
    input {
      @include font($search-bar, "OpenSans-Regular", 1.6rem, normal);
      line-height: 4.5rem;
      width: 30rem;
      padding: 0rem 6rem 0rem 2rem;
      outline: none;
      border: 0.1rem solid rgba(0, 0, 0, 0.24);
      border-radius: 0.4rem;
      &::placeholder {
        @include font($search-bar, "OpenSans-Regular", 1.6rem, normal);
      }
    }
  }

  a {
    img {
      margin-top: 1rem;
    }
  }
  .search {
    background: $primary;
    position: absolute;
    right: 0;
    border: none;
    height: 100%;
    width: 5rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
