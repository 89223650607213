@media only screen and (min-width: 1600px) {
  .wrapper {
    .left-section {
      .left-inner-box {
        .mobile-mockup {
          height: 92.2rem;
          width: 44.2rem;
          .mobile-mockup-image-section {
            position: absolute;
            height: 87rem;
            width: 42rem;
            bottom: 1.3rem;
            left: 1.3rem;
            border-radius: 5rem;
          }
        }
      }
    }
  }

  .after {
    .left-section {
      .left-inner-box {
        .mobile-mockup {
          height: 67rem;
          width: 34rem;

          .mobile-mockup-image-section {
            height: 64rem;
            width: 32rem;
            bottom: 1.5rem;
            left: 1rem;
            border-radius: 4rem;
          }
        }
      }
    }
    .right-section {
      .right-inner-box {
        .hashtag-content {
          max-height: 20vh;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1200px),
  only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1200px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1200px),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 1200px),
  only screen and (min-resolution: 192dpi) and (min-width: 1200px),
  only screen and (min-resolution: 2dppx) and (min-width: 1200px) { */

/* @media screen and (min-width: 1200px) {
  .wrapper {
    .left-section {
      .left-inner-box {
        .mobile-mockup {
          height: 72.2rem;
          width: 34rem;
          .mobile-mockup-image-section {
            position: absolute;
            height: 70rem;
            width: 32rem;
            bottom: 1.2rem;
            left: 1.2rem;
            overflow: hidden;
            border-radius: 3rem;
            background: $primary;
            .center-image {
              height: 13rem;
              width: 13rem;
            }
          }
        }
      }
    }
    .right-section {
      .right-inner-box {
        margin: 70% 0 0 34%;
      }
    }
  }

  .after .right-section .right-inner-box .caption-content {
    max-height: 33rem;
  }

  .wrapper .right-section .right-inner-box .downloading-section {
    margin-top: 3rem;
  }

  .after .right-section .right-inner-box .content .inner-section .left {
    width: 95%;
  }
  .after {
    .left-section {
      .left-inner-box {
        .mobile-mockup {
          height: 60rem;
          width: 29rem;
          .mobile-mockup-image-section {
            transition: bottom 1s, transform 1s, height 0.3s 1s, width 0.3s 1s, left 0.3s 1s;
            height: 58rem;
            width: 28rem;
            bottom: 0.5rem;
            left: 0.5rem;
          }
        }
      }
    }
    .right-section {
      .right-inner-box {
        margin: 17% 10% 0% 15%;
        height: 100%;
      }
    }
  }
}


 */
